import { useState, useEffect, React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login, reset } from '../services/auth/authSlice.js';
import Spinner from '../components/Spinner';

function Login() {

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = formData;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        } 
        if (isSuccess || user) {
            navigate('/');
        }
        dispatch(reset());
    }, [user, isError, isSuccess, message, navigate, dispatch]);

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email, password
        }
        dispatch(login(userData));
    }

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className='container loginOverride'>
        <section className="heading">
            <h1 className="center">Login</h1>
        </section>
        <section className="form">
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                    <input type="email" className="form-control" id="email" name="email" value={email} placeholder='email' autoComplete='on' onChange={onChange}/>
                </div>
                <div className='form-group'>
                    <input type="password" className="form-control" id="password" name="password" value={password} placeholder='password' autoComplete='on' onChange={onChange}/>
                </div>
                <div className="form-group">
                    <button type="submit" className='btn btn-block'>Login</button>
                </div>
            </form>
        </section>
        </div>
    )
}

export default Login
